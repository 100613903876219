

export default {
    name: "TxtNovidadesVersao",
    props: {
        slide: {
            type: Number,
            required: true,
        },
    },
    computed: {
        getUf() {
            return this.$store.state.uf;
        }
    }
}
