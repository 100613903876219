
import { Component, Vue } from 'vue-property-decorator';
import NovidadesVersao from './components/NovidadesVersao.vue'
import empresaService from '@/models/empresa.service';
import store from './store'

@Component({
  store,
  components: {
    NovidadesVersao
  }
})
export default class App extends Vue {

  get exibirNovidadesVersao(): boolean {
    return this.$store.state.showModal
  }

  // exibir(): void {
  //   window.dispatchEvent(new CustomEvent('exibirNovidadesVersao'));
  // }

  exibirNovidades(): void {
    document.body.style.overflow = 'hidden';
    this.$store.commit('setExibirOcultarNovidades', true)
  }

  verificaCrmExibeNovidades(): void {

    const dadosStorage = localStorage.getItem('VERSAO_SISTEMA')

    let versaoSistema = 0

    if(dadosStorage) {
      versaoSistema = parseFloat(dadosStorage)
    }

    if(!dadosStorage || versaoSistema !== 2.29) {
      localStorage.setItem('VERSAO_SISTEMA', '2.29')
      localStorage.removeItem('ARRAY_CRMS_NAO_EXIBIR_NOVIDADES')
    }

    if(!empresaService.validaEmpresaEstaLocalStorage()) {
      
      this.exibirNovidades();
    }
  }
  criarEventos(): void {
    // eslint-disable-next-line
    window.addEventListener('exibirNovidadesVersao', (e: CustomEventInit) => {
      const uf = e.detail?.uf;

      this.$store.commit('setUF', uf)

      const reexibirNovidades = e.detail?.reexibirNovidades;

      const ValidaDataExibicao = localStorage.getItem('ULTIMA_EXIBICAO_NOVIDADES') !== new Date().toLocaleDateString()

      if(ValidaDataExibicao){
        this.verificaCrmExibeNovidades()
      }

      if(reexibirNovidades) {
        this.exibirNovidades();
      }
    })
  }

  created(): void {
    this.criarEventos()
  }
}
