class EmpresaService {
    getEmpresaEscolhidaFromStorage() {

        const localStorageData = localStorage.getItem('emp_escolhida')

        if(localStorageData) {
            return JSON.parse(localStorageData);
        }
        return undefined;
    }
    
    validaEmpresaEstaLocalStorage(): boolean {

        const localStorageData = localStorage.getItem('ARRAY_CRMS_NAO_EXIBIR_NOVIDADES')
        
        if(localStorageData && !!this.getEmpresaEscolhidaFromStorage()) {
            const listaEmpresas =  JSON.parse(localStorageData)

            return listaEmpresas.find((el: string[]) => el === this.getEmpresaEscolhidaFromStorage().idExterno) ? true : false
        }else{
            return false
        }
    }

    validaAdicionaEmpresaStorage(): void {
        
        let listaEmpresas = []
        const empresaStorage = localStorage.getItem('emp_escolhida')
        const empresaEscolhida: EmpresaEscolhidaType = empresaStorage ? JSON.parse(empresaStorage) : {idExterno: ''}
        
        const crmsLocalStorageData = localStorage.getItem('ARRAY_CRMS_NAO_EXIBIR_NOVIDADES')

        if(crmsLocalStorageData){
            listaEmpresas = JSON.parse(crmsLocalStorageData);
            if(!this.validaEmpresaEstaLocalStorage()) {
            listaEmpresas.push(empresaEscolhida.idExterno)
            this.adicionaListaCrmLocalStorage(listaEmpresas)
            }
        }else{
            listaEmpresas.push(empresaEscolhida.idExterno)
            this.adicionaListaCrmLocalStorage(listaEmpresas)
        }
    }

    adicionaListaCrmLocalStorage(listaEmpresas: string[]): void {
        
        localStorage.setItem('ARRAY_CRMS_NAO_EXIBIR_NOVIDADES', JSON.stringify(listaEmpresas))
    }
}

const empresaServiceSingleton = new EmpresaService();

Object.freeze(empresaServiceSingleton);

export default empresaServiceSingleton;

type EmpresaEscolhidaType = {
    idExterno: string
}