
import { Component, Vue } from 'vue-property-decorator';
import empresaService from '@/models/empresa.service';
import DefaultTemplate from '@/components/DefaultTemplate.vue'

@Component({
  components:{
    DefaultTemplate
  }
})
export default class NovidadesVersao extends Vue {

  // Prop -> maxSlides
  // Deverá sempre contar com o primeiro e último step das novidades
  public maxSlides = this.$store.state.uf === 'RJ' ? 6 : 5;
  public slide = 1

  finalizarExibicaoNovidades(): void{

    empresaService.validaAdicionaEmpresaStorage()

    this.ocultarNovidades()
  }
  desativarNovidadesUmDia(): void {
      localStorage.setItem('ULTIMA_EXIBICAO_NOVIDADES', new Date().toLocaleDateString())
      this.ocultarNovidades()
  }

  ocultarNovidades(): void {
    document.body.style.overflow = '';
    this.$store.commit('setExibirOcultarNovidades', false)
  }
  verificaEscPrescionado(e: KeyboardEvent): void {
    
    if (e.key === 'Escape') {
      this.desativarNovidadesUmDia()
    }
  }
  eventosCreated(): void {
    window.addEventListener('keydown', this.verificaEscPrescionado)
  }
  eventosRemoved(): void {
    window.removeEventListener('keydown', this.verificaEscPrescionado)
  }

  mounted(): void {
    this.eventosCreated()
  }
  destroyed(): void {
    this.eventosRemoved()
  }
}
