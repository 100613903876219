import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showModal: false,
    uf: ''
  },
  mutations: {
    setExibirOcultarNovidades(state, payload){
      state.showModal = payload
    },
    setUF(state, uf){
      state.uf = uf;
    }
  },
  actions: {
  },
  modules: {
  }
})
