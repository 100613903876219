
import OpeningSvg from '@/assets/images/OpeningSvg.vue'
import EndingSvg from '@/assets/images/EndingSvg.vue'
import CurrentVersion from '@/components/CurrentVersion.vue'

export default {
  name: "default-template",
  props: {
    maxSlides: {
      type: Number,
      required: true,
    },
    slide: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // Alterar aqui para a nova versão...
    version: '2.29.00',
    completedTasksNumber: 183
  }),
  components: {
    OpeningSvg,
    EndingSvg,
    CurrentVersion
  },
}
